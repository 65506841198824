import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../../../components/Layout';
import HeadingDecorated from '../../../components/HeadingDecorated';
import TeamMember from '../../../components/TeamMember';
import Seo from '../../../components/Seo';
import { LOCALIZED_PAGES } from '../../o-nas/wladze-instytutu';
import '../../../css/about.scss';

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    {
      zarzad: allStrapiTeamMember(
        sort: { fields: position, order: ASC }
        filter: {
          localizations: {
            data: { elemMatch: { attributes: { locale: { eq: "en" } } } }
          }
          section: { eq: "zarzad" }
        }
      ) {
        nodes {
          localizations {
            data {
              attributes {
                bio
                fullName
                jobTitle
              }
            }
          }
          avatar {
            localFile {
              url
            }
          }
          email
        }
      }
      rada: allStrapiTeamMember(
        sort: { fields: position, order: ASC }
        filter: {
          localizations: {
            data: { elemMatch: { attributes: { locale: { eq: "en" } } } }
          }
          section: { eq: "rada" }
        }
      ) {
        nodes {
          localizations {
            data {
              attributes {
                bio
                fullName
                jobTitle
              }
            }
          }
          avatar {
            localFile {
              url
            }
          }
        }
      }
    }
  `);

  return (
    <Layout locale="en" localizedPages={LOCALIZED_PAGES}>
      <Seo title="About us" locale="en" />
      <div className="about">
        <section>
          <HeadingDecorated className="about__section-heading">
            Management Board
          </HeadingDecorated>
          <div className="about__section-content">
            {data.zarzad.nodes.map((item) => (
              <TeamMember
                data={item}
                key={item.localizations.data[0].attributes.fullName}
              />
            ))}
          </div>
        </section>
        <section>
          <HeadingDecorated className="about__section-heading">
            Foundation Council
          </HeadingDecorated>
          <div className="about__section-content">
            {data.rada.nodes.map((item) => (
              <TeamMember
                data={item}
                key={item.localizations.data[0].attributes.fullName}
              />
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AboutPage;
